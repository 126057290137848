
import { defineComponent, onMounted, ref } from "vue";
import { config } from "@/core/helpers/config";
import { themeName } from "@/core/helpers/documentation";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DirectSponsoredHistory from "@/components/directsponsored/DirectSponsoredHistory.vue";
// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from  "@/views/ECShopAdvisory.vue";

export default defineComponent({
  name: "directsponsored",
  components: {
    DirectSponsoredHistory,
    // BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("directsponsoredTab") || "0");

      setCurrentPageTitle("Direct Sponsored");
    });

    
  }
});
